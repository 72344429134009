<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    :options.sync="options"
    :server-items-length="totalItems"
    :loading="loading"
    sort-by="date"
    :sort-desc="true"
    must-sort
    class=""
    calculate-widths
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>{{ $t('Activities') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            :to="{ name: 'ActivitiesCreate' }"
            fab
            dark
            color="primary"
        >
            <v-icon
            dark
            >
            mdi-plus
            </v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        flat
        class="mt-3"
      >
        <v-select
          v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1"
          :items="users"
          item-text="fullName"
          item-value="id"
          v-model="options.userId"
          clearable
          :label="$t('Filter Account Manager')"
          class="mt-3 pr-1 top-filters shrink"

          dense
        ></v-select>
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
        <template v-slot:activator="{ on, attrs }">
        <v-text-field
              v-model="date"
              clearable
              class="mt-3 pr-1 top-filters shrink"
              v-bind="attrs"
              v-on="on"
              placeholder="dd-mm-yyyy"

              dense
        ></v-text-field>
        </template>
        <v-date-picker
              v-model="dateFormatted"
              no-title
              @input="menu2 = false"
              :first-day-of-week="1"
        ></v-date-picker>
        </v-menu>
      </v-toolbar>
    </template>
    <template v-if="$store.state.user.user.role == 0  || $store.state.user.user.role == 1" v-slot:item.userId="{ item }">

        {{ getUser(item.userId) }}

    </template>
     <template v-slot:item.date="{ item }">

        {{ formatDate( new Date(item.date).toISOString().substr(0, 10) )}}

    </template>
    <template v-slot:item.activities="{item}" >
      <!-- <v-chip-group > -->
        <v-chip v-for="(result,i) in getActivity(item.activities)" :key="i"
          class="ma-2"
          :color="result.color"
          text-color="white"
        >{{result.text}} <v-avatar color="white" class="amount-txt" :style="'color:'+result.color" size="25">{{result.amount}}</v-avatar></v-chip>
      <!-- </v-chip-group> -->
    </template>
    <template v-slot:item.actions="{item}" >
        <v-btn :to="{name: 'ActivitiesEdit', query: { id: item.id, page: options.page }}" plain text icon class="mr-2">
            <v-hover v-slot="{ hover }">
            <v-icon :color="hover? 'green': 'grey'"
            >
            mdi-pencil
            </v-icon>
          </v-hover>
        </v-btn>

        <v-btn :to="{name: 'ActivitiesDelete', query: { id: item.id,name:getUser(item.userId), page: options.page }}" plain text icon class="">
            <v-hover v-slot="{ hover }">
              <v-icon  :color="hover? 'primary': 'grey'"
              >
              mdi-delete
              </v-icon>
            </v-hover>
        </v-btn>
    </template>
    <template v-slot:no-data>
      {{ $t('No items found') }}
    </template>
  </v-data-table>
</template>

<script>
    export default {
        data(){
            return{
                search: '',
                listSrch: {},
                totalItems: 0,
                menu2:false,
                date:'',
                dateFormatted:'',
                options: {},
                loading: true,
                activityTypes:[],
                users:[],
                headers: [],
                items: [],
            }
        },

        watch: {
            options: {
                handler () {
                  this.fetchItems()
                },
                deep: true,
            },
            dateFormatted () {
                this.date = this.formatDate(this.dateFormatted)
                this.options.date = this.dateFormatted
                this.fetchItems()
            }
        },
        mounted ()
        {
            this.fetchItems();
        },
        created: function()
        {
            if(this.$route.query.page > 0){
              this.options.page = parseInt(this.$route.query.page);
            }

            this.fetchUsers();
            this.fetchActivityTypes();

            if(this.$store.state.user.user.role == 0  || this.$store.state.user.user.role == 1){
              this.headers.push({ text: this.$t('Account Manager'), align: 'start', value: 'userId' ,sortable: false})
            }

            this.headers.push(
              { text: this.$t('Date'), value: 'date', sortable: true },
              { text: this.$t('Activities'), value: 'activities', sortable: false },
              { text: this.$t('Actions'), align: 'right', value: 'actions', sortable: false }
            )

        },

        methods: {
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            getUser(userid){
              let filterUser = this.users.filter(function(user){
                return user.id == userid
              })
              return filterUser[0]!=undefined?filterUser[0].fullName:'';
            },
            getActivity(activities){
              let activityItems = [];
              for(let act in activities) {
                if(activities[act].amount > 0){
                  let filterActivity = [];
                  if(this.activityTypes != undefined){
                    filterActivity = this.activityTypes.filter(function(activity){
                      return activity.id == activities[act].activityTypeId;
                    })
                  }
                  if(filterActivity[0]!=undefined){
                    activityItems.push({text:filterActivity[0]!=undefined? filterActivity[0].name:'',amount:activities[act].amount,color:filterActivity[0]!=undefined? filterActivity[0].color:"grey",})
                  }
                }
              }
              return activityItems;
            },
            fetchUsers()
            {
                let uri = this.$urlPrefix + '/User/CountAll?&includeDeleted=true';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/User/GetList?start=0&count='+response.data+"&includeDeleted=true";
                    this.axios.get(uri2).then((response) => {
                        this.users = response.data.filter(function(user){
                          return user.role == 1||user.role == 2;
                      });
                    });
                });
            },
            fetchActivityTypes()
            {
                let uri = this.$urlPrefix + '/ActivityType/CountAll';
                this.axios.get(uri).then((response) => {
                    let uri2 = this.$urlPrefix + '/ActivityType/GetList?start=0&count='+response.data;
                    this.axios.get(uri2).then((response) => {
                        this.activityTypes = response.data;
                        this.loading = false;
                    });
                });
            },
            fetchItems()
            {
              this.$router.replace({ query: { page: this.options.page } }).catch(()=>{});

              let uri = this.$urlPrefix + '/Activity/CountAll';
              let uri2 = this.$urlPrefix + '/Activity/GetList?start='+ ((this.options.page -1) * this.options.itemsPerPage) +'&count='+this.options.itemsPerPage;
              if(this.options.userId){
                uri2 += '&userId='+this.options.userId;
              }

              if(this.options.date){
                uri2 += '&date='+this.options.date;
              }
              if(this.options.sortBy){
                uri2 += '&sort='+this.options.sortBy[0];
              }
               if(this.options.sortDesc){
                uri2 += '&sortDescending='+this.options.sortDesc[0];
              }
              this.axios.get(uri).then((response) => {
                this.totalItems = response.data;
                this.axios.get(uri2).then((response) => {
                    this.items = response.data;
                    this.loading = false;
                });
              });
            },
        }
    }
</script>
<style lang="scss">
  .top-filters{
    width: 150px;
  }

  .amount-txt {
    left:8px;
    font-weight:bold;
  }
</style>
