var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"sort-by":"date","sort-desc":true,"must-sort":"","calculate-widths":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('Activities')))]),_c('v-spacer'),_c('v-btn',{attrs:{"to":{ name: 'ActivitiesCreate' },"fab":"","dark":"","color":"primary"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-toolbar',{staticClass:"mt-3",attrs:{"flat":""}},[(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?_c('v-select',{staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"items":_vm.users,"item-text":"fullName","item-value":"id","clearable":"","label":_vm.$t('Filter Account Manager'),"dense":""},model:{value:(_vm.options.userId),callback:function ($$v) {_vm.$set(_vm.options, "userId", $$v)},expression:"options.userId"}}):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-3 pr-1 top-filters shrink",attrs:{"clearable":"","placeholder":"dd-mm-yyyy","dense":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}})],1)],1)]},proxy:true},(_vm.$store.state.user.user.role == 0  || _vm.$store.state.user.user.role == 1)?{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUser(item.userId))+" ")]}}:null,{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( new Date(item.date).toISOString().substr(0, 10) ))+" ")]}},{key:"item.activities",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.getActivity(item.activities)),function(result,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"color":result.color,"text-color":"white"}},[_vm._v(_vm._s(result.text)+" "),_c('v-avatar',{staticClass:"amount-txt",style:('color:'+result.color),attrs:{"color":"white","size":"25"}},[_vm._v(_vm._s(result.amount))])],1)})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{name: 'ActivitiesEdit', query: { id: item.id, page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'green': 'grey'}},[_vm._v(" mdi-pencil ")])]}}],null,true)})],1),_c('v-btn',{attrs:{"to":{name: 'ActivitiesDelete', query: { id: item.id,name:_vm.getUser(item.userId), page: _vm.options.page }},"plain":"","text":"","icon":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-icon',{attrs:{"color":hover? 'primary': 'grey'}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('No items found'))+" ")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }